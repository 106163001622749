import React from 'react';
import Layout from '../components/Layout'
import { Container, Row, Col } from 'reactstrap';
import Banner from '../images/Our-Story/banner.jpg'
import Story from '../images/About/banner3.jpeg'
import "firebase/auth";


function OurStory() {
    return (
        <Layout>
            <section className="story_banner">
                <Container>
                    <Row>
                        <Col md="12">
                <img src={Banner} alt="img" />
                </Col>
                </Row>
                </Container>
            </section>
            <section className="story_section">
                <Container>
                    <Row>
                        <Col md="6">
                            <h1>Our Story</h1>
                            <hr/>
                            <p>The chapter of Grey Space began with a question triggered in my mind after completion of my studies that "What next ?" And being a woman, that too married in a traditional family, it was really very difficult to think of doing business. I thought of discussing the issue with my in-laws and other family members. I was lucky to get a positive response from all of them.<br/>
                            After having a deep discussion with them,  It came to my knowledge that my ancestors were into wood work in early 90s. Thereafter, I came with an idea to give rebirth to the same work with a new look, hence Grey Space came into existence in 2019. We are a neonate in the field of furniture business fostered by quality work  with creativity and imagination to match the today’s demand of furniture.<br/>
                            A dynamic workshop ambience has been created offering opportunities to designers to explore their ideas in excellent conditions and bring them to the highest level of design, functionality and technological artisanship.<br/>
                            Many products have been developed surpassing a number of iterations until reaching their final form, which would be impossible without meticulous craftsmanship and handwork of the highest quality.<br/>
                            Predominantly local materials are used, especially solid wood, and most of the production takes place within the company itself or in collaboration with local cooperatives.</p>
                        </Col>
                        <Col md="6">
                            <div className="story_img">
                                <img src={Story} alt="img" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    );
}

export default OurStory;
